@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.SearchComponent {
  padding: 0 15px 0 44px;
  width: 280px;
  height: 44px;
  background: $white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  outline: none;
  border: none;
  background-image: url('../../images/utils-icons/search.svg');
  background-repeat: no-repeat;
  background-position: 16px;
  background-size: 24px;
  box-sizing: border-box;
  @include fontBold16;
  color: $font-color;

  &::placeholder {
    color: $font-color;
  }
}

[data-theme=true] .SearchComponent {
  color: $dark-font-grey;
  background-image: url('../../images/utils-icons/search.svg') invert(0);
  background-repeat: no-repeat;
  background-position: 16px;
  background-size: 24px;
  filter: invert(1);
}