@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.TemplateCard {
  width: 22vw;
  background: white;
  // box-shadow: 0px 2px 20px $box-shadow;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 20px $box-shadow-hover;
  }

  &__title {
    width: 100%;
    background: $header-light-blue;
    box-shadow: 0px 2px 20px $box-shadow;
    border-radius: 20px 20px 0px 0px;
    padding: 16px 20px;
    box-sizing: border-box;
    color: $font-color;
    @include fontBold16;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 20px 16px;
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0 0 20px 20px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: $color-background-light-blue;
  }

  &__icon-img {
    width: 32px;
    height: 32px;
  }

  &__text {
    height: 62px;
    width: 100%;
    padding: 16px 22px;
    background: $gray-data;
    border-radius: 10px;
    @include fontMedium12;
    color: $font-color;
    box-sizing: border-box;
  }
}

[data-theme=true] .TemplateCard {
  background: $dark-background-section;
  color: $dark-font-color;

  &__main {
    border-left: 1px solid #333;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
  }

  &__title {
    background: $dark-header-dark-blue;
    color: $dark-font-color;
  }

  &__text {
    background: $dark-background;
    color: $dark-font-color;
  }

  &__icon img{
    filter: invert(1);
  }
}