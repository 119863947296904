@mixin FontExtraBold32 {
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
}

@mixin fontBold40 {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
}

@mixin fontBold24 {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
}

@mixin fontBold20 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

@mixin fontBold18 {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}

@mixin fontBold16 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

@mixin fontLightBold48 {
  font-weight: 600;
  font-size: 48px;
  line-height: 48px
}

@mixin fontLightBold36 {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
}

@mixin fontLightBold32 {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
}

@mixin fontLightBold24 {
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
}

@mixin fontLightBold20 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

@mixin fontLightBold16 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@mixin fontMedium20 {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

@mixin fontMedium18 {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

@mixin fontMedium16 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

@mixin fontMedium14 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

@mixin fontMedium12 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

@mixin fontMedium10 {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}
