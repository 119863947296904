@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.ResultPage {
  display: flex;
  flex-direction: column;
  padding: 0 72px;
  gap: 31px;

  .btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > *:not(:last-of-type) {
      margin-right: 14px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    @include FontExtraBold32;
    color: $font-color;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 70vh;
    overflow-y: auto;
    padding-right: 16px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
      border-radius: 40px;
    }
  }

  &__main-wrapper {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
    background: $data-color;
    border-radius: 20px;
    padding: 40px 16px 40px 40px;
    box-sizing: border-box;
    width: 100%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-right: 56px;
    overflow-y: auto;
    box-sizing: border-box;
    @include fontMedium16;
    color: $font-color;

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
      border-radius: 40px;
    }
  }

  &__main *{
    margin: revert;
    padding: revert;
    font: revert;
    list-style: revert;
    border-collapse: revert;
    border-spacing: revert;
    border: revert;
    vertical-align: revert;
  }

  &__copy {
    position: absolute;
    right: 28px;
    top: 28px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}

[data-theme=true] {
  .ResultPage {
    &__title {
      color: $dark-font-color;
    }

    &__main-wrapper {
      background: $dark-background;
    }

    &__main {
      color: $dark-font-color;
    }

    .btn-group img, .btn-group svg {
      filter: invert(1);
    }
  }
}