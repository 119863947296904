@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.DashboardCreatedCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 36px 21px 36px 36px;
  background: $data-color;
  box-shadow: 0px 2px 20px $box-shadow;
  border-radius: 18.0592px;

  &__main {
    display: flex;
    flex-direction: column;
    width: 60%;

    & > h2, p {
      @include fontMedium16;
      color: $font-color;
    }
  }

  &:hover {
    box-shadow: 0px 2px 20px $box-shadow-hover;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &> img {
      width: 24px;
      height: 24px;
    }
  }

  &__main *{
    margin: revert;
    padding: revert;
    font: revert;
    list-style: revert;
    border-collapse: revert;
    border-spacing: revert;
    border: revert;
    vertical-align: revert;
  }
}

[data-theme=true] .DashboardCreatedCard {
  background: $dark-background;
  color: $dark-font-color;

  &__main > h2, p {
    color: $dark-font-color;
  }

  &__btns {
    & img {
      filter: invert(1);
    }
  }
}