@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.MainBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $button-blue;
  box-shadow: 0px 2px 20px $box-shadow;
  border-radius: 20px;
  color: $color-template-card;
  cursor: pointer;
}

[data-theme=true] .MainBtn {
  background-color: $dark-primary;
  color: $dark-background-pricing;
}

.big {
  padding: 8px 20px;
  @include fontMedium16;
}

.small {
  padding: 6px 16px;
  @include fontMedium14;
}

.disable {
  background: rgba(13, 87, 201, 0.7);
}