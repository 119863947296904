@import '../../../styles/utils/variables';

.deleteBtn {
  box-sizing: border-box;
  width: 174px;
  height: 38px;
  border: 1px solid #A63A3A;
  border-radius: 20px;
  color: #A63A3A;
  font-size: 16px;

  &:hover {
    color: #FAFDFE;
    background-color: #A63A3A;
    cursor: pointer;
  }

  [data-theme=true] &:hover {
    color: $dark-color-template-card;
    background-color: $dark-error-color;
  }
}

[data-theme=true] .deleteBtn {
  color: $dark-color-template-card;
  background-color: $dark-error-color;
}

[data-theme=true] .coach .deleteBtn {
  color: $dark-error-color;
  background-color: $font-color;
  border-color: $dark-error-color;

  &:hover {
    color: $dark-color-template-card;
    background-color: $dark-error-color;
  }
}

