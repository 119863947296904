@import '../../styles/utils/variables';

.modal {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__overlay {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    z-index: -1;
  }

  &__content {
    box-sizing: border-box;
    position: relative;
    width: 460px;
    padding: 32px;
    background-color: #fff;
    border-radius: 20px;
    border: none;
    transform: translateY(-100%);
  }

  &__close {
    box-sizing: border-box;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    box-sizing: border-box;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 20px;
    line-height: 27px;
  }

  &__btns {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
  }

  &__btn {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 28px;
    }

    &--delete {
      padding: 8px 20px;
      color: #fff;
      background-color: #A63A3A;
      border-radius: 20px;

      &:hover {
        background-color: darken(#A63A3A, 10%);
      }
    }

    &--confirm {

    }

    &--cancel {
      &:hover {
        text-decoration: underline;
      }
    }
  }

}

[data-theme=true] {
  .modal {
    &__content {
      background-color: $dark-background-section;
    }

    &__title {
      color: $dark-font-color;
    }

    &__text {
      color: $dark-font-color;
    }

    &__btn {
      &--delete {
        background-color: $error-color;
      }

      &--cancel {
        color: $scrollbar-color;
      }
    }

    &__overlay {
      background-color: #FFFFFF33;
    }
  }
}
