@import '../../styles/utils/variables.scss';

.PricingPage{
    height: -webkit-fill-available;
    background-image: url('../../images/pricing-bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 50px ;

    &__logo{
        display: flex;
        align-items: center;

        img{
            width: 176px;
            height: 45px;
        }

        p{
            font-size: 24px;
            font-weight: 700;
        }
    }

    &__title{
        width: 100%;
        

        h1{
            font-size: 32px;
            font-weight: 700;
            text-align: center;
        }
    }

    &__plans{
        display: flex;
        justify-content: center;
        column-gap: 20px;
        margin-top: 60px;
    }
}

[data-theme=true] .PricingPage{
    background-color: $dark-background-pricing;
    background-image: none;

    &__title h1{
        color: $dark-font-color;
    }
}