@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.primary-btn {
  & * {
    box-sizing: border-box;
  }

  padding: 8px 20px;
  @include fontMedium16;
  color: $white;
  border-radius: 20px;
  background-color: $primary;
  cursor: pointer;

  &:hover {
    background-color: $primary-hover;
  }

  &:disabled {
    background-color: $primary-disabled;
  }
}

[data-theme=true] .primary-btn {
  background-color: $dark-primary;
  color: $dark-background-pricing;
}
