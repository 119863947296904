@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.upload-page {
  & * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0 62px 0 72px;
  position: relative;

  &__preview {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: calc(100% - 134px);
    top: 64px;
    height: calc(100% - 88px);
    z-index: 100;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-color;
      border-radius: 10px;
      margin-left: 20px;
    }

    & > p {
      width: 100%;
      padding: 40px;
      @include fontBold18;
      background-color: $color-background-orange;
      border-radius: 20px;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }
}
