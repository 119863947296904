@import '../../../styles/utils/variables';

.message {
  position: relative;
  width: 680px;
  height: auto;
  padding: 25px 44px;
  border-radius: 40px;
  margin-bottom: 28px;

  &--left {
    margin-left: 20px;
    align-self: flex-start;
    background-color: #EDF1F6;
  }

  &--right {
    margin-right: 20px;
    align-self: flex-end;
    background-color: rgba(77, 189, 238, 0.3);
  }

  .decor {
    position: absolute;
    z-index: 1;

    &--left {
      left: -15px;
      bottom: -3px;

      svg>path {
        fill: #C4DDFF;
      }
    }

    &--right {
      right: -16px;
      bottom: -1px;
      transform: scaleX(-1);

      svg>path {
        fill: #FEE2C5;
      }
    }
  }

  &__text {
    line-height: 22px;
  }

  &__text *{
    margin: revert;
    padding: revert;
    font: revert;
    list-style: revert;
    border-collapse: revert;
    border-spacing: revert;
    border: revert;
    vertical-align: revert;
    text-wrap: inherit;
  }

  &__text pre{
    border: 1px solid $border-blue;
    border-radius: 10px;
    padding: 5px;
    background-color: $color-background-blue;
  }
}

[data-theme=true] .message {
  &--left {
    background-color: $dark-background;
  }

  &--right {
    background-color: $dark-message-background;
  }

  .decor {
    &--left {
      svg>path {
        fill: $dark-background-section;
      }
    }

    &--right {
      svg>path {
        fill: $dark-message-background;
      }
    }
  }

  &__text {
    color: $dark-font-color;
  }
}
