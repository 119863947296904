@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.scratch-page {
  & * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0 72px;

  &__header {

  }

  &__title {
    @include FontExtraBold32;
  }

  &__btn-group {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 16px;

    button {
      margin-right: 20px;
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: start;
    overflow-y: auto;
    margin-top: 16px;

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-color;
      border-radius: 10px;
      margin-left: 20px;
    }
  }

  &__content-list {
    width: 100%;
  }

  &__optional-fields {
    width: 530px;
    margin-top: 20px;
    padding: 24px 40px;
    border: 1px solid #D9D9D9;
    border-radius: 20px;
  }
}

[data-theme=true] .scratch-page {
  background: $dark-background-section;

  &__title {
    color: $dark-font-color;
  }

  &__optional-fields {
    border: 1px solid #333;
  }
}
