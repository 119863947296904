@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins'; 

.BalanceSection {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 3vw 0 72px;
  }

  &__main {
    display: flex;
    flex-direction: row;
    gap: 23px;
    padding: 24px 0 0 72px;
  }

  &__item-wrapper {
    width: 327px;
  }

  &__item {

    &--price {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3px;
      height: 100%;
      @include fontLightBold36;
    }
  
    &--plan {
      @include fontMedium16;
    }

    &--date {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      @include fontMedium20;
    }
  }
}

[data-theme=true] {
  .BalanceSection {
    &__item--price {
      color: $dark-font-color;
    }
    &__item--date {
      color: $dark-font-color;
    }
    &__item--plan {
      color: $dark-font-color;
    }
  }
}