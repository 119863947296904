.toggle-switch {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    
    .toggle-switch-checkbox {
        height: 0;
        width: 0;
        visibility: hidden;
    }
        
    .toggle-switch-slider {
        position: relative;
        display: inline-block;
        width: 50px; // Width of the slider
        height: 24px; // Height of the slider
        background: #ccc;
        border-radius: 24px;
        transition: background-color 0.2s;
        
        &:before {
            content: '';
            position: absolute;
            left: 2px;
            top: 2px;
            width: 20px;
            height: 20px;
            background: white;
            border-radius: 50%;
            transition: 0.2s;
        }
    }

    .toggle-switch-checkbox:checked + .toggle-switch-slider {
        background: #4D90FE; // Color when toggle is active
    }

    .toggle-switch-checkbox:checked + .toggle-switch-slider:before {
        transform: translateX(26px); // Move the toggle ball to the right
    }

    .toggle-switch-label {
        margin-left: 8px; // Spacing between the slider and the label text
        user-select: none;
    }

    .toggle-switch-right{
        margin-left: 12px;
    }

    .toggle-switch-left{
        margin-right: 12px;
    }

    .unactive-dark {
        color: #686868;
    }

    .active-dark {
        color: #EDF1F6;
    }

    .unactive-light{
        color: #ACADAD;
    }

    .active-light{
        color: #000A10;
    }
}