@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: #000000;
  
  img {
    width: 176px;
    height: 45px;
    margin-right: 8px;
  }

  &.auth {

    img {
      width: 242px;
      height: 61px;
      margin-right: 24px;
    }

    h1 {
      @include fontBold40;
    }
  }
}
