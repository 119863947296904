@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.content-item {
  position: relative;
  padding: 32px 40px 60px;
  background-color: $color-background-grey;
  border-radius: 20px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    min-width: 100px;
    height: 44px;
    margin-right: 28px;
    @include fontBold18;
  }

  &__error {
    position: absolute;
    @include fontMedium12;
    top: -18px;
    color: $error-color;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    & > div {
      position: relative;
      width: 100%;

      & > textarea {
        width: 100%;
        overflow-y: hidden;
        min-height: 44px;
        resize: none;
        padding: 12px 24px;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        border: none;
        outline: none;

        &.error {
          border: 2px solid $error-color;
        }
      }
    }
  }

  & > button {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 40px;
    bottom: 22px;
    cursor: pointer;
  }
}

[data-theme=true] .content-item {
  background-color: $dark-background;

  &__title {
    color: $dark-font-color;
  }

  &__error {
    color: $dark-error-color;
  }

  &__inner > div > textarea {
    background-color: $dark-background-section;
    color: $dark-font-color;
  }
}
