@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.TemplatesSection {
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-height: 100%;

  &__header--padding {
    padding-left: 72px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 100%;
    scrollbar-width: 4px;
    margin-right: 16px;

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
      border-radius: 40px;
    }
  }

  &__title {
    @include fontBold24;
    color: $font-color;
    padding-left: 72px;
  }

  [data-theme=true] &__title {
    color: $dark-font-color;
  }

  &__list {
    padding: 24px 0 24px 72px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }
  
  &__spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}