@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.LoginPage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__logo {
    width: 172px;
    height: 55px;
    margin-bottom: 24px;

    @media (max-height: 780px) {
      width: 152px;
      height: 35px;
      margin-bottom: 18px;
    }
  }

  &__btns {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 16px;
  }

  &__privacy {
    @include fontBold16;
    color: $gray-privacy;
    cursor: pointer;
  }

  &__error {
    position: absolute;
    top: 40px;
    right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 14px 18px;
    border: 1px solid $error-color;
    color: $error-color;
    box-shadow: 0px 2px 20px $box-shadow;
    border-radius: 10px;
    background-color: $white;
    @include fontMedium16;

    &> img {
      width: 24px;
      height: 24px;
    }
  }

  [data-theme=true]  &__error{
    border: 1px solid $dark-error-color;
    color: $dark-error-color;
    background-color: #0F0F0F;
  }

  &__toggle{
    display: flex;
    align-self: end;
    padding-right: 54px;
    position: absolute;
    top: 40px;
  }
}