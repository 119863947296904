@import '../../styles/utils/variables';
@import '../../styles/utils/mixins'; 

.ExplainPage {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0 72px;
  height: 100%;

  &__main {
    height: 100%;
    overflow: hidden;
    background: $data-color;
    border-radius: 20px;
    padding: 40px 20px 40px 40px;
  }

  @media (max-height: 780px) {
    gap: 18px;
  }

  &__title {
    color: $font-color;
    @include FontExtraBold32;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    gap: 20px;

    &>button {
      position: relative;
    }
  }
}

[data-theme=true] .ExplainPage {
  background: $dark-background-section;

  &__title {
    color: $dark-font-color;
  }

  &__main {
    border: 1px solid $dark-font-grey;
    background-color: $dark-background-section;
  }
}