@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.optional-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &__title {
    @include fontBold18;
  }

  &__input-select {
    min-width: 190px;

    & > label {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      &:not(:last-of-type) {
        margin-right: 18px;
      }

      & > span {
        display: inline-block;
        margin-left: 8px;
        @include fontBold16;
      }

      & > input {
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid transparent;
        background-color: transparent;
        border-radius: 20px;

        &:before {
          position: absolute;
          content: '';
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 1px solid $primary;
          border-radius: 20px;
        }

        &:checked {
          &:after {
            position: absolute;
            content: '';
            display: inline-block;
            background-color: $primary;
            width: 10px;
            height: 10px;
            border-radius: 20px;
            top: 5px;
            left: 5px;
          }
        }
      }
    }
  }

  &__input-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > label {
      position: relative;
      display: inline-flex;
      align-items: center;
      width: 44px;
      height: 20px;
      border-radius: 20px;
      overflow: hidden;

      & > input {
        position: absolute;
        opacity: 0;
      }

      & > input:checked + div {
        background-color: $primary;
        left: 0;
        right: auto;
      }

      & > div {
        position: absolute;
        display: inline-flex;
        align-items: center;
        padding: 4px 5px 4px 6px;
        background-color: #ACADAD;
        right: 0;
      }

      & > div > span {
        @include fontMedium10;
        color: #fff
      }

      span:nth-of-type(2) {
        display: inline-block;
        margin: 0 3px 0 6px;
        width: 14px;
        height: 14px;
        background-color: #fff;
        border-radius: 20px;
      }
    }
  }

  &__input-range {
    & > label {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > span {
        @include fontBold16;
        margin-right: 6px;
      }

      & > input {
        width: 66px;
        height: 8px;
      }
    }
  }
}

[data-theme=true] .optional-field {
  &__title {
    color: $dark-font-color;
  }

  label {
    color: $dark-font-color;
  }

  input {
    background-color: $dark-font-grey;
  }

  &__input-select > label > input:checked {
    &:before {
      border: 1px solid $dark-primary;
    }

    &:after {
      background-color: $dark-primary;
    }
  }

  &__input-checkbox > label > input:checked + div {
    background-color: $dark-primary;
  }
}