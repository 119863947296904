@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.InboxSection {
  display: flex;
  flex-direction: column;
  gap: 31px;
  max-height: 100%;

  &__title {
    @include FontExtraBold32;
    color: $font-color;
    padding-left: 72px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 62vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: 4px;

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
      border-radius: 40px;
    }
  }

  &__list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 40px;
    gap: 16px;
    border-top: 0.4px solid $scrollbar-color;

    &:first-child {
      border-top: none;
    }

    &>img {
      width: 81px;
      height: 25px;
    }

    &>div {
      width: 60%;
      border-radius: 40px;
      color: $font-color;
      @include fontBold16;
    }
  }

  &__delete-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    cursor: pointer;

    &>img {
      width: 24px;
      height: 24px;
    }
  }
}

.lds-ellipsis-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: 20px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #6483bd;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

[data-theme=true] {
  .InboxSection {
    &__title {
      color: $dark-font-color;
    }

    &__list-item {
      border-top: 0.4px solid #333;
      background-color: $dark-background;
    }

    &__list-item div {
      color: $dark-font-color;
    }

  }
}