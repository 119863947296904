@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.TextArea {
  &__label {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 28px;
    color: $font-color;
    @include fontBold18;

    @media (max-height: 780px) {
      gap: 14px;
    }
  }

  &__textarea {
    height: 44px;
    padding: 14px 24px;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    color: $font-color;
    @include fontMedium12;
    border: 1px solid $scrollbar-color;
    border-radius: 10px;

    &--error {
      border: 1px solid #A63A3A;
    }
  }

  &__tooltip {
    position: absolute;
    top: 8px;
    right: 120px;
    background-color: $white;
    color: $error-color;
    padding: 16px 17px;
    box-shadow: 0px 2px 20px $box-shadow;
    border-radius: 10px;
    z-index: 1;
    @include fontMedium12;
    line-height: 12px;

    @media (max-height: 780px) {
      top: 2px;
      padding: 10px 17px;
    }
  }

  &__arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: $white transparent transparent transparent;
    bottom: -6px;
    left: 16px;
    transform: translateX(-50%);
  }
}

.textarea__text {
  margin-bottom: 10px;
}

.textarea__error {
  position: absolute;
  @include fontMedium12;
  bottom: -13px;
  color: $error-color;
}

[data-theme=true] .textarea__text {
  color: $dark-font-color;
}

[data-theme=true] .textarea__error{
  color: $dark-error-color;
}
