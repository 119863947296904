@import '../../styles/utils/_variables.scss';
@import '../../styles/utils/_mixins.scss';

.filter {
  position: relative;

  & * {
    box-sizing: border-box;
  }

  &__action-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & > span {
      @include fontBold16;
      margin-left: 10px;
    }
  }

  [data-theme=true] &__action-button {
    color: $dark-font-color;
  }
  
  [data-theme=true] &__action-button svg {
    filter: invert(1);
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: start;
    position: absolute;
    min-width: 140px;
    right: 105px;
    top: 10px;
    padding: 8px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
  }

  [data-theme=true] &__list {
    background-color: $dark-background-modal;
  }

  &__item {
    display: inline-block;
    cursor: pointer;
    width: 100%;
    padding: 4px 12px;
    border-radius: 8px;
    text-align: left;

    &:hover {
      background-color: #C4DDFF;
    }

    [data-theme=true] &:hover {
      background-color: $dark-background;
    }

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }

    &--selected {
      background-color: #C4DDFF;
    }

    [data-theme=true] &--selected {
      background-color: $dark-background;
    }
  }

  [data-theme=true] &__item {
    color: $dark-font-color;
  }
}
