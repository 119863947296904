@import '../../../styles/utils/_variables';

.chat-form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;

  &__error {
    color: rgba(255, 0, 0, 0.47);
  }

  &__writing {
    height: 18px;
    display: inline-block;
    margin-bottom: 12px;
    color: #ACADAD;
    font-size: 18px;
  }

  &__wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  &__input {
    box-sizing: border-box;
    width: 680px;
    min-height: 54px;
    border: 1px solid #ACADAD;
    border-radius: 20px;
    padding: 16px 20px;

    &::placeholder {
      color: $scrollbar-color;
    }
  }

  &__submit {
    box-sizing: border-box;
    width: 27px;
    height: 27px;
    margin-top: 13px;
    margin-left: 27px;
    cursor: pointer;
  }
}

[data-theme=true] .chat-form {
  &__input {
    border: 1px solid $scrollbar-color;
    background: $dark-background-section;
    color: $scrollbar-color;
  }

  &__writing {
    color: $scrollbar-color;
  }

  &__submit {
    filter: invert(1);
  }
}

