@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.Video {
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    margin-top: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: 10px;
    margin-left: 20px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding-right: 10px;
  }

  @media (max-height: 780px) {
    gap: 18px;
  }

  &__title {
    color: $font-color;
    @include fontBold24;
  }

  &__text {
    color: $font-color;
    @include fontBold18;

    p {
      margin-bottom: 16px;
    }

    li {
      margin-left: 20px;
      margin-bottom: 12px;
      list-style-type: disc;
    }
  }
}

[data-theme=true] .Video {
  &__title {
    color: $dark-font-color;
  }

  &__text {
    color: $dark-font-color;
  }
}
