@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.LoginPageBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $button-blue;
  box-shadow: 0px 2px 20px $box-shadow;
  border-radius: 20px;
  padding: 8px 20px;
  color: $color-template-card;
  cursor: pointer;
}

[data-theme=true] .LoginPageBtn {
  background-color: $dark-primary;
  box-shadow: 0px 2px 20px $box-shadow-hover;
  color: $dark-color-template-card;
}
