@import '../../styles/utils/variables.scss';

.PricingCard{
    width: 100%;
    max-width: 294px;
    padding: 25px 40px;
    border-radius: 20px;
    box-sizing: border-box;

    &__header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 18px;

        &_icon{
            margin-right: auto;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 
            0px 1px 2px 0px rgba(0, 0, 0, 0.1),
            0px 3px 3px 0px rgba(0, 0, 0, 0.09),
            0px 7px 4px 0px rgba(0, 0, 0, 0.05),
            0px 13px 5px 0px rgba(0, 0, 0, 0.01),
            0px 21px 6px 0px rgba(0, 0, 0, 0);
        }
    }

    &__tag{
        background-color: #fff;
        width: fit-content;
        padding: 6px;
        border-radius: 10px;
        font-size: 12px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
 0px 8px 8px 0px rgba(0, 0, 0, 0.09),
 0px 17px 10px 0px rgba(0, 0, 0, 0.05),
 0px 31px 12px 0px rgba(0, 0, 0, 0.01),
 0px 48px 13px 0px rgba(0, 0, 0, 0);

    }

    &__title{
        h2{
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 8px;
        }

        p{
            font-size: 14px;
            line-height: 16.37px;
        }

        &--white {
            display: flex;
            flex-direction: column;
            align-items: center;

            h2{
                font-size: 16px;
                line-height: 22px;
                font-weight: 700;
                margin-bottom: 8px;
                text-align: center;
                width: 166px;
            }
    
            p{
                color:#686868;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                text-align: center;
                width: 211px;
            }
        }
    }

    &__price{
        display: flex;
        align-items: center;
        margin-top: 26px;
        column-gap: 10px;

        &_price{
            font-size: 32px;
            font-weight: 700;
        }

        &_period{
            font-size: 14px;
            color: rgba(46, 46, 46, 1);
        }
    }

    &__divider{
        width: 100%;
        height: 1px;
        background-color: rgba(104, 104, 104, 1);
        margin: 15px 0;
    }

    &__benefits {
        &--white {
            margin-top: 32px;
        }
    }

    &__benefit{
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin-bottom: 10px;

        &_icon{
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 30px;

            &--white {
                min-width: 30px;
                max-width: 30px;
                background-color: #C4DDFF;
            }
        }

        &_content{
            h3{
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 2px;
            }

            p{
                font-size: 12px;
            }

            &--white {
                h3{
                    font-size: 12px;
                    font-weight: 400;
                    white-space: nowrap;
                }
            }
        }
    }

    &__get{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30px;
        background-color: rgba(0, 10, 16, 1);
        color: #fff;
        text-decoration: none;
        font-weight: 700;
        font-size: 12px;
        border-radius: 8px;
        cursor: pointer;
        transition: .1s;

        &:hover{
            background-color: #fff;
            color: #000;
        }

        &--white {
            background-color: #1363DF;
            border: 1px solid transparent;
            margin-top: 35px;

            &:hover{
                border: 1px solid #1363DF;
                background-color: #fff;
                color: #1363DF;
            }
        }
    }


    &_bg_white{
        background: #FAFDFE;
        border: 3px solid #1363DF;
        padding-top: 56px;
    }

    &_bg_blue{
        background-color: #C4DDFF;
    }

    &_bg_gradient{
        background: linear-gradient(159.13deg, #1363DF -30.48%, rgba(19, 99, 223, 0) 120.47%);
        
    }
}

[data-theme=true] .PricingCard{
    &__tag{
        background-color: $dark-background-pricing;
        color: $dark-font-color;
    }

    &__title{
        &--white {
            h2{
                color: #fff;
            }
    
            p{
                color: #fff;
            }
        }

        &--blue {
            h2{
                color: $dark-font-color;
            }
    
            p{
                color: $dark-font-color;
            }
        }

        &--gradient {
            h2{
                color: $dark-font-color;
            }
    
            p{
                color: $dark-font-color;
            }
        }
    }

    &__price{
        &_price{
            color: $dark-font-color;
        }

        &_period{
            color: $dark-font-color;
        }
    }

    &__divider{
        background-color: $dark-font-color;
    }

    &__benefits {
        &--white {
            margin-top: 32px;
        }
    }

    &__benefit{
        &_icon{
            &--white {
                background-color: #1363DF;

                svg{
                    filter: invert(1);
                }
            }
        }

        &_content{
            h3{
                color: $dark-font-color;
            }

            p{
                color: $dark-font-color;
            }

            &--white {
                h3{
                    color: $dark-font-color;
                }
            }
        }
    }

    &__header{
        &_icon{
            background-color: $dark-background-pricing;

            svg{
                filter: invert(1);
            }
        }
    }

    &__benefit {
        &_icon {
            &--blue {
                background-color: $dark-background-pricing;

                svg {
                    filter: invert(1);
                }
            }

            &--gradient {
                background: $dark-background-pricing;

                svg {
                    filter: invert(1);
                }
            }
        }
    }

    &__get{
        &--white {
            background-color: $dark-header-blue;
            color: $dark-background-pricing;

            &:hover{
                background-color: $dark-background-pricing;
                color: $dark-header-blue;
            }
        }

        &--blue {
            background-color: $dark-font-color;
            color: $dark-background-pricing;

            &:hover{
                background-color: $dark-background-pricing;
                color: $dark-font-color;
            }
        }

        &--gradient {
            background-color: $dark-font-color;
            color: $dark-background-pricing;

            &:hover{
                background-color: $dark-background-pricing;
                color: $dark-font-color;
            }
        }
    }

    &_bg_white{
        background: $dark-background-pricing;
        border: 3px solid $button-blue;
    }

    &_bg_blue{
        background-color: $dark-header-dark-blue;
    }
}