@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.DashboardTotalInfoCard {
  display: flex;
  flex-direction: column;
  background: $color-background-white; 
  box-shadow: 0px 2px 20px $box-shadow;
  border-radius: 20px;
  width: 100%;
  height: 160px;

  &:hover {
    box-shadow: 0px 2px 20px $box-shadow-hover;
  }

  @media (max-height: 780px) {
    height: 140px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $header-blue;
    color: $font-color;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
    border-radius: 20px 20px 0px 0px;
    padding: 12px 22px;
    color: white;
  }

  &__header.balance{
    background-color: $extra-header-blue;
    color: black;
  }

  &__header-title {
    @include fontBold16;
    color: white;
  }

  &__header-title.balance {
    @include fontBold16;
    color: black;
  }

  &__header-img {
    width: 24px;
    height: 24px;
    filter: invert(1);
  }

  &__main {
    position: relative;
    height: 100%;
    padding-left: 20px;
    border-bottom: 1px solid $border-dashboard;
    border-left: 1px solid $border-dashboard;
    border-right: 1px solid $border-dashboard;
    border-radius: 0px 0px 20px 20px;
  }

  &__main > p {
    position: absolute;
    top: 30px;
    left: 20px;
    @include fontLightBold48;

    @media (max-height: 780px) {
      top: 20px;
    }
  }
}

[data-theme=true] .DashboardTotalInfoCard{
  background: $dark-background-pricing;

  &__header {
    background-color: $dark-card-border;
  }

  &__header.balance{
    background-color: $dark-header-dark-blue;
    color: $dark-font-color;
  }

  &__header-title {
    color: $dark-background-pricing;
  }

  &__header-title.balance {
    color: $dark-font-color;
  }

  &__header-img {
    filter: invert(0);
  }

  &__main > p {
    color: $dark-font-color;
  }

  &__main {
    border-bottom: 1px solid #333;
    border-left: 1px solid #333;
    border-right: 1px solid #333;
  }
}