@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.MainHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 72px;

  &__title {
    @include FontExtraBold32;
    color: $font-color;
  }

  [data-theme=true] &__title {
    color: $dark-font-color;
  }
}