@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.SelectModelDropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  &__button {
    box-sizing: border-box;
    padding: 10px 16px;
    border-radius: 10px;
    border: 0.5px solid #135BE3;
    background: $color-template-card;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    @include fontMedium14;
  }

  [data-theme=true] &__button {
    border: 0.5px solid #686868;
    background: $dark-background-pricing;
    color: $dark-font-color;
  }
  
  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 200;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__list-wrapper {
    width: 120px;
    border-radius: 10px;
    box-sizing: border-box;
    background: $color-template-card;
    overflow: hidden;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  }

  [data-theme=true] &__list-wrapper {
    background: $dark-background-modal;
  }
  
  &__item {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    @include fontMedium14;

    &:hover {
      color: $color-template-card;
      background: $scrollbar-color;
    }

    &--role {
      &:hover {
        background: $qa-hover;
      }
    }

    [data-theme=true] &--role {
      &:hover {
        background: #002255;
      }
    }
  }

  [data-theme=true] &__item {
    color: $dark-font-color;

    &:hover {
      color: $dark-font-color;
      background: $dark-header-dark-blue;
    }
  }
}