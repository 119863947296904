@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.Main {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 42px 0;
  box-sizing: border-box;

  @media (max-height: 780px) {
    padding: 35px 0;
  }

  &__loader {
    width: 100vw;
  }

  &__section {
    height: 100%;
    width: 100%;
    background: $color-background-white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
    border-radius: 40px 0px 0px 40px;
    padding: 44px 0;
    overflow-x: visible;
    box-sizing: border-box;

    @media (max-height: 780px) {
      padding: 30px 0;
    }
  }

  [data-theme=true] &__section {
    background: $dark-background-section;
  }
}