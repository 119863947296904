@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.PrivacyPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 40px 24px 40px 40px;
    background: $color-background-login;
    box-shadow: 0 2px 20px $box-shadow;
    border-radius: 20px;
    width: 722px;
    box-sizing: border-box;
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  &__btn {
    position: absolute;
    right: 0;
    width: 24px;
    height: 24px;
    top: 0;
    transform: translateY(-100%);
    cursor: pointer;
  }

  &__title {
    @include FontExtraBold32;
    color: $font-color;
  }

  &__info {
    @include fontMedium16;
    color: $font-color;
    height: 50vh;
    overflow: auto;
    padding-right: 22px;

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
      border-radius: 40px;
    }

    p {
      margin-bottom: 16px;
    }

    h5 {
      margin-bottom: 16px;
      font-weight: 600;
    }

    li {
      margin-left: 20px;
      margin-bottom: 12px;
      list-style-type: disc;
    }

    a {
      margin-left: 6px;
    }
  }
}


[data-theme=true] .PrivacyPage {
  &__box {
    background: $dark-background-modal;
  }

  &__title {
    color: $dark-font-color;
  }

  &__info {
    color: $dark-font-color;

    a {
      color: $primary;
    }
  }
}
