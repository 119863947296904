@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.GoogleBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 11px;
  width: 432px;
  height: 48px;
  border: 1px solid $border-gray-color;
  border-radius: 10px;
  @include fontMedium16;
  color: $gray-privacy;
  cursor: pointer;

  @media (max-height: 780px) {
    @include fontMedium14;
    height: 43px;
  }

  &:hover {
    border: 0.5px solid $border-blue;
  }

  &__img {
    width: 24px;
    height: 24px;

    @media (max-height: 780px) {
      width: 20px;
      height: 20px;
    }
  }
}

[data-theme=true] .GoogleBtn {
  border: 1px solid $dark-border-gray-color;
  color: $gray-privacy;
  background-color: $dark-background;
  cursor: pointer;

  &:hover {
    border: 0.5px solid $dark-primary;
  }
}
