@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.coach {
  box-sizing: border-box;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__top-bar {
    box-sizing: border-box;
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    width: 100%;
    height: 110px;
    margin-top: -44px;
  }

  &__role-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  &__default-message {
    padding-top: 15%;
    font-size: 28px;
    flex-grow: 1;
    color: #aeaeae;
  }

  &__message-list {
    flex-grow: 1;
    margin: auto;
    scroll-behavior: revert;
    box-sizing: border-box;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 1140px;

    // hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

[data-theme=true] {
  .coach {
    &__default-message {
      color: $dark-font-grey;
    }
  }
}
