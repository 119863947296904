
$white: #fff;
$color-background-blue: #D8E1ED;
$color-background-grey: #EDF1F6;
$color-background-light-blue: rgba(77, 189, 238, 0.4);
$color-background-white:white;
$color-background-orange:#FEE2C5;
$color-background-blue-active:#4DBDEE;
$dark-background-blue-active:#64C6F0;
$color-template-card:#FAFDFE;
$dark-color-template-card:#101010;
$color-background-login: #FDFDFD;
$error-color: #A63A3A;
$dark-error-color: #B94141;
$border-dashboard: #D9D9D9;

$primary: #1363DF;
$dark-primary: #236AED;
$primary-hover: #0D57C9;
$primary-disabled: rgba(13, 87, 201, 0.7);

$borger-color:#06283D;
$border-gray-color:#DFDFDF;
$dark-border-gray-color:#2D2D2D;
$border-blue:#4285F4;
$button-blue:#1363DF;
$qa-hover: #C3DBFF;
$font-color:#000A10;
$dark-font-color:#fff;
$header-blue:#3D83ED;
$dark-header-blue:#236AED;
$dark-header-dark-blue:#025;
$header-light-blue:rgba(195, 219, 255, 1);
$extra-header-blue:#C3DBFF;
$gray-data:#EDF1F7;
$gray-privacy:#A19E9E;
$scrollbar-color:#ACADAD;
$data-color: rgba(237, 241, 246, 1);

$box-shadow: rgba(0, 0, 0, 0.06);
$box-shadow-hover: rgba(0, 0, 0, 0.12);

$dark-background: #1A1A1A;
$dark-background-modal: #0F0F0F;
$dark-background-section: #0D0D0D;
$dark-background-pricing: #101010;
$dark-font-grey: #686868;
$dark-card-border: #5492EF;
$dark-message-background: rgba(100, 198, 240, 0.30);