@import '../../../../styles/utils/variables';
@import '../../../../styles/utils/mixins';

.DashboardList {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid $border-dashboard;
  border-radius: 20px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 17px 40px;
    background: $extra-header-blue;
    box-shadow: 0 2px 20px $box-shadow;
    border-radius: 20px 20px 0 0;
    box-sizing: border-box;

    @media (max-height: 780px) {
      padding: 12px 40px;
    }
  }

  [data-theme=true] &__header {
    background: $dark-header-dark-blue;
  }

  &__title {
    flex-grow: 1;
    color: $font-color;
    @include fontBold24;
  }

  [data-theme=true] &__title {
    color: $dark-font-color;
  }

  &__list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 27px;
    padding: 24px 50px 30px 50px;
    height: auto;
  }
}

[data-theme=true] .DashboardList {
  border: 1px solid #333;
  border-radius: 20px;
}
