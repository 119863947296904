@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.Loader {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__title {
    @include FontExtraBold32;
  }
}

[data-theme=true] {
  .Loader {
    &__title {
      color: $dark-font-color;
    }
  }

}

.LoaderScratch {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__title {
    @include FontExtraBold32;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__main-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    @include fontBold20;
    color: $font-color;

    &> img {
      width: 32px;
      height: 32px;
    }
  }
}

[data-theme=true] {
  .LoaderScratch {
    &__title {
      color: $dark-font-color;
    }

    &__main-row {
      color: $dark-font-color;
    }

    svg{
      filter: invert(1);
    }
  }
}

#wrapper {
  position: relative;
  /*background:#333;*/
  height: 40%;
}

.profile-main-loader {
  margin-left: -100px;
  width: 45px;
  z-index: 9000 !important;
}

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 195px;
  height: 195px;
}

.profile-main-loader .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {
  0% {
    stroke: $button-blue;
  }

  40% {
    stroke: $button-blue;
  }

  66% {
    stroke: $button-blue;
  }

  80%,
  90% {
    stroke: $button-blue;
  }
}

@keyframes color {
  0% {
    stroke: $button-blue;
  }

  40% {
    stroke: $button-blue;
  }

  66% {
    stroke: $button-blue;
  }

  80%,
  90% {
    stroke: $button-blue;
  }
}