@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.file-input {
  padding: 40px;
  background-color: $color-background-orange;
  border-radius: 20px;

  &__title {
    @include fontBold18;
    margin-bottom: 28px;
  }

  &__subtitle {
    display: inline-block;
    width: 280px;
    padding: 14px 67px;
    margin-right: 20px;
    @include fontMedium12;
    color: #ACADAD;
    background-color: white;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
  }

  &__upload {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > input {
      display: none;
    }
  }

  &__uploaded {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 15px;
      @include fontBold20;
    }
  }

}
