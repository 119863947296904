@import '../../../styles/utils/variables';

.copy-btn {
  box-sizing: border-box;
  padding: 2px 3px;
  border-radius: 12px;

  img {
    width: 24px;
    height: 24px;
  }

  [data-theme=true] img {
    filter: invert(1);
  }

  &:hover {
    cursor: pointer;
    border-color: rgba(77, 77, 77, 0.65);
  }
}
