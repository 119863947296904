@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.LoginForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__modal {
    height: 200px;
    width: 300px;
  }

  &__modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 20px;
    color: $font-color;
  }

  [data-theme=true] &__modal-content {
    color: $dark-font-color;
  }

  &__modal-title {
    @include fontLightBold32;
  }

  &__modal-text {
    text-align: center;
    @include fontLightBold20;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    margin-bottom: 18px;
    @include fontLightBold20;
    color: $font-color;

    @media (max-height: 780px) {
      @include fontLightBold16;
      margin-bottom: 14px;
    }
  }

  [data-theme=true] &__label {
    color: $dark-font-color;
  }

  &__input {
    width: 432px;
    height: 48px;
    padding: 13px 0 13px 24px;
    box-sizing: border-box;
    border: 1px solid $border-gray-color;
    border-radius: 10px;
    @include fontMedium16;
    color: $font-color;

    &--error {
      border: 1px solid $error-color;
      background-color: #EECACA;
    }

    [data-theme=true] &--error {
      border: 1px solid $dark-error-color;
      background-color: $dark-error-color;
    }

    &:focus{
      border-color: $primary;
    }

    [data-theme=true] &:focus {
      border-color: $dark-primary;
    }

    &::placeholder {
      color: $gray-privacy;
    }

    [data-theme=true] &::placeholder {
      color: $gray-privacy;
    }

    @media (max-height: 780px) {
      height: 40px;
      padding: 10px 0 10px 22px;
    }
  }

  [data-theme=true] &__input {
    border: 1px solid $dark-border-gray-color;
    color: $dark-font-color;
    background-color: $dark-background-modal;
  }

  &__forgot {
    text-align: left;
    width: 100%;
    @include fontMedium16;
    color: $button-blue;
    margin-bottom: 24px;
    cursor: pointer;
  }

  [data-theme=true] &__forgot {
    color: $dark-primary;
  }

  &__tooltip {
    color: $error-color;
    @include fontMedium14;
    line-height: 12px;

    @media (max-height: 800px) {
      @include fontMedium12;
    }
  }

  [data-theme=true] &__tooltip {
    color: $dark-error-color;
  }
}