@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 496px;
  padding: 32px;
  box-sizing: border-box;
  gap: 16px;
  background: $color-background-login;
  box-shadow: 0px 2px 20px $box-shadow;
  border-radius: 20px;

  @media (max-height: 800px) {
    padding: 20px;
    gap: 10px;
  }

  &__title {
    color: $font-color;
    @include fontLightBold32;

    @media (max-height: 780px) {
      @include fontLightBold24;
    }
  }

  [data-theme=true] &__title {
    color: #FFF;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__blue {
    @include fontLightBold20;
    color: $button-blue;

    @media (max-height: 780px) {
      font-size: 18px;
    }

    &--margin {
      margin: 32px 0;

      @media (max-height: 800px) {
        margin: 8px 0;
      }
    }
  }

  [data-theme=true] &__blue {
    color: $dark-header-blue;
  }

  &__line {
    height: 1px;
    width: 100%;
    background-color: $border-gray-color;
    margin-top: 32px;

    @media (max-height: 800px) {
      margin-top: 14px;
    }
  }

  [data-theme=true] &__line {
    background-color: $dark-border-gray-color;
  }

  &__sign-in {
    cursor: pointer;
  }
}

[data-theme=true] .Login {
  background: $dark-background-modal;
  box-shadow: 0px 2px 20px $box-shadow;
  border-radius: 20px;
}