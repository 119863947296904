@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.secondary-btn {
  & * {
    box-sizing: border-box;
  }

  display: flex;
  align-items: center;
  padding: 6px 16px;
  @include fontMedium14;
  color: $primary;
  border: 2px solid $primary;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: rgba(174, 174, 174, 0.26);
  }

  &:disabled {

  }

  & > svg {
    transform: translateY(-1px);
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  & > svg, & > path {
    fill: $primary;
  }

  &.right {
    align-self: end;
  }
}
