@import './styles/utils/variables';
@import './styles/utils/mixins';

.App {
  overflow-x: hidden;
  margin: 0 auto;
  height: 100vh;
  background-color: #EDF1F6;
}

.App[data-theme=true] {
  background-color: #1E1E1E;
}
