@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.Sidebar {
  width: 270px;
  display: flex;
  flex-direction: column;
  padding: 12px 45px 0;
  box-sizing: border-box;

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    @include fontBold24;
    color: $font-color;
  }

  [data-theme=true] &__title {
    color: $dark-font-color;
  }

  &__dropdown {
    margin-top: 32px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 11px;
    width: 100%;
  }

  
  &__dropdown-title{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    width: 180px;
    cursor: pointer;
    padding-left: 10px;
    transition: all 0.2s ease-in-out;
    gap: 12px;
    @include fontBold18;
    box-sizing: border-box;
    color: $font-color;
  }

  [data-theme=true] &__dropdown-title {
    color: $dark-font-color;
  }

  &__list-link,
  &__logout {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    width: 180px;
    cursor: pointer;
    padding-left: 10px;
    // transition: all 0.2s ease-in-out;
    gap: 12px;
    @include fontBold18;
    box-sizing: border-box;
    color: $font-color;

    &:hover {
      background: $color-background-blue-active;
      border-radius: 10px;
      color: #FFFFFF;
    }

    [data-theme=true] &:hover {
      background: $dark-background-blue-active;
      border-radius: 10px;
      color: $dark-color-template-card;
    }

    &:hover img{
      filter: invert(1);
    }

    [data-theme=true] &:hover img{
      filter: invert(0);
    }

    &--active {
      background: $color-background-blue-active;
      border-radius: 10px;
      color: #FFFFFF;
    }

    [data-theme=true] &--active {
      background: $dark-background-blue-active;
      color: $dark-color-template-card!important;
    }

    &--active img{
      filter: invert(1);
    }

    [data-theme=true] &--active img{
      filter: invert(0);
    }
  }

  [data-theme=true] &__list-link,
  [data-theme=true] &__logout{
    color: $dark-font-color;
  }

  &__dot {
    left: 26px;
    top: 9px;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid gray;
    background: linear-gradient(235.91deg, #D61C1C 17.54%, #FFFFFF 224.06%);
    box-sizing: content-box;
  }

  &__list-link-icon {
    width: 24px;
    height: 24px;
  }

  [data-theme=true] &__list-link-icon {
    filter: invert(1);
  }

  &__border {
    position: relative;
    left: -9px;
    width: 196px;
    height: 0.6px;
    background-color: $borger-color;
    margin-top: 27px;
    margin-bottom: 27px;
  }

  &__logout {
    margin-top: 6vh;
  }

  &__toggle {
    margin-top: auto;
  }
}