@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.MiniLoader {
  display: flex;
  width: 32px;

  #MiniLoader__wrapper {
      position: relative;
      height: 10%;
    }
  
    .MiniLoader__profile-main-loader {
      margin-left: -100px;
      width: 32px;
      z-index: 9000 !important;
    }
  
    .MiniLoader__profile-main-loader .loader {
      position: relative;
      margin: 0px auto;
      width: 32px;
      height: 32px;
    }
  
    .MiniLoader__profile-main-loader .MiniLoader__loader:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  
    .MiniLoader__circular-loader {
      -webkit-animation: rotate 2s linear infinite;
      animation: rotate 2s linear infinite;
      height: 100%;
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
    }
  
    .MiniLoader__loader-path {
      stroke-dasharray: 150, 200;
      stroke-dashoffset: -10;
      -webkit-animation: dash 1.5s ease-in-out infinite, color2 6s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite, color2 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  
    @-webkit-keyframes rotate {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  
    @keyframes rotate {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  
    @-webkit-keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
  
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
  
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
  
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
  
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
  
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
  
    @-webkit-keyframes color2 {
      0% {
        stroke: $scrollbar-color;
      }
  
      40% {
        stroke: $scrollbar-color;
      }
  
      66% {
        stroke: $scrollbar-color;
      }
  
      80%,
      90% {
        stroke: $scrollbar-color;
      }
    }
  
    @keyframes color2 {
      0% {
        stroke: $scrollbar-color;
      }
  
      40% {
        stroke: $scrollbar-color;
      }
  
      66% {
        stroke: $scrollbar-color;
      }
  
      80%,
      90% {
        stroke: $scrollbar-color;
      }
    }
}