@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.PricingLoader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-background-blue;
}

[data-theme=true] .PricingLoader {
  background-color: $dark-background-pricing;
}

.DashboardSection {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  
  &__header--padding {
    padding-left: 72px;
  }

  &__main {
    display: flex;
    flex-shrink: 2;
    flex-direction: column;
    gap: 33px;
    margin-top: 28px;
    max-height: 100%;
    overflow-y: auto;
    margin-right: 24px;
    height: 100%;

    @media (max-height: 780px) {
      gap: 25px;
      margin-top: 15px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
      border-radius: 40px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__row-list {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 0 41px 0 72px;
  }

  &__subtitle {
    color: $font-color;
    @include fontBold24;
    padding-left: 72px;
  }

  [data-theme=true] &__subtitle {
    color: $dark-font-color;
  }

  &__list-wrapper {
    display: flex;
    flex-shrink: 2;
    height: auto;
    width: 100%;
    padding: 0 41px 0 72px;
    box-sizing: border-box;
  }
}
