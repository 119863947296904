@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.HelpSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 31px;
  max-height: 100%;
  width: 100%;

  &__title {
    @include FontExtraBold32;
    color: $font-color;
    padding-left: 72px;
    margin-top: 10vh;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    margin-right: 20px;
    width: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      margin-top: 20px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
      border-radius: 40px;
    }
  }

  &__button-wrapper {
    width: 65%;
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    border-top: 1px solid $scrollbar-color;
    @include fontBold18;
    color: $font-color;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      background: $qa-hover;
    }

    &--active {
      background: $qa-hover;
    }

    &>img {
      width: 24px;
      height: 24px;
    }
  }

  &__info {
    width: 100%;
    padding: 16px 26px 19px;
    box-sizing: border-box;
    @include fontMedium18;
    color: $font-color;
  }
}

[data-theme=true] {
  .HelpSection {
    &__title {
      color: $dark-font-color;
    }

    &__button {
      color: $dark-font-color;
      border-top: 1px solid $scrollbar-color;
    }

    &__button:hover {
      background: $dark-header-dark-blue;
    }

    &__button img {
      filter: invert(1);
    }

    &__button--active {
      background: $dark-header-dark-blue;
    }

    &__info {
      color: $dark-font-color;
    }
  }
}